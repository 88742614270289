import React, { useEffect, useRef, useState } from 'react';
import useScrollListener from '../../lib/useScrollListener';

type Post = {
  id: string;
  mediaUrl: string;
  permalink: string;
  caption: string;
  mediaType: 'IMAGE' | 'VIDEO' | 'CAROUSEL_ALBUM';
  thumbnailUrl: string;
  timestamp: number;
  children: any[];
}

const translateRatios = [-50,30,0,-20-80];

const InstagramFeed = () => {

  const [images, setImages] = useState<Post[]>([]);
  const followContainer = useRef<HTMLDivElement>(null);
  const imageRefs = useRef(new Array(5));

  useEffect(() => {
    function getImages() {
      fetch('https://feeds.behold.so/NcxSQgY74jM7mmrxnt7l')
        .then(response => response.json())
        .then((photos: Post[]) => {
          setImages(photos);
        });
    }

    getImages();
  }, []);

  const animate = (e: any) => {
    if (!followContainer.current) return;

    const dimensions = followContainer.current.getBoundingClientRect();

    if (dimensions.top - window.innerHeight < 0 && dimensions.bottom > 0) {
      const scrolledRatio = (window.innerHeight - dimensions.top) / window.innerHeight;

      imageRefs.current.forEach((image, index) => {
        const translateRatio = translateRatios[index] ? translateRatios[index] : 0;
        image && (image.style.transform = `translateY(${scrolledRatio * translateRatio}px)`);
      });
    }
  }

  useScrollListener(animate);

  return <footer className="pt-5">
    <div ref={followContainer} className="custom-container px-3 mb-5 footer-follow">
      <div className="row footer-follow--header">
        <div className="px-3 footer-follow--title">
          <p
            className="font-size-display1 mb-3"
            style={{ maxWidth: '26rem' }}
          >
            Follow us on Instagram for more updates
          </p>
          <div className="d-flex">
            <a
              href="https://instagram.com/moziemugs/"
              target="_blank" rel="noopener noreferrer"
              className="d-flex py-3 align-items-center font-color-black borderbottom border-color-black"
            >
              <p className="mr-3">Follow us</p>
              <img src="/icon/arrow-long-right.svg" alt="Arrow icon"/>
            </a>
          </div>
        </div>
      </div>
      <div className="d-flex footer-follow--images">
        {images.filter(x => x.mediaType === 'IMAGE'|| x.mediaType === 'CAROUSEL_ALBUM').slice(0, 5).map((item, i) => (
          <div key={i} className="justify-content-sm-end flex-column follow-images">
            <a
              href={item.permalink} target="_blank" rel="noopener noreferrer"
              ref={image => imageRefs.current[i] = image}
              style={{
                paddingBottom: '100%',
                background: `url("${item.mediaUrl}") center center/cover`
              }}
            />
          </div>
        ))}
      </div>
    </div>
  </footer>;
}

export default InstagramFeed;
